.form-label {
  color: $color-dark-blue;
  font-size: 18px;
  font-weight: 200;
}

.form-label-between {
  color: $color-dark-blue;
  font-size: 18px;
  font-weight: 200;
  line-height: 40px;
  text-align: center;
}
.form-radio {
  margin-right: 15px;
  input[type="radio"] {
    margin-left: 0  ;
  }
  label {
    color: $color-dark-blue;
    font-size: 18px;
    font-weight: 200;
    margin-left: 10px;
  }
}
.form-input {
  height: 40px;
  font-size: 15px;
  border: 1px solid $color-border-form;
  padding-left: 12px;
  width: 100%;
  &:focus {
    outline: none;
  }
}
.form-input-select {
  background: #fff url("/build/styles/select.png") no-repeat 100% 0;
  padding-right: 36px;
  padding-left: 12px;
}
.clip-select {
  margin: 0;
  display: block;
  position: relative;
  border: 1px solid $color-border-form;
  overflow: hidden;
  background: #fff url("/build/styles/select.png") no-repeat 100% 0px;
  select {
    color: #000000;
    font-size: 15px;
    border: none;
    box-shadow: none;
    background: transparent none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 38px;
    padding-right: 36px;
  }
}

.fa-form-input {
  font-size: 13px;
}

.form-multiselect {
  .btn-group {
    display: block;
  }

  .open > .btn-default.dropdown-toggle {
    color: #333;
    border-color: #abd7e9;
    background: #fff url("/build/styles/select.png") no-repeat 100% 0px;
  }

  .multiselect-container {
    top: 36px;
    width: 100%;
  }

  .multiselect-container>li {
    padding: 0;
    display: block;
    float: left;
    width: 50%;
    &.multiselect-group{
      width: 100%;
      padding-top: 7px;
    }
    label{
      input{
        margin-left: -18px;
        margin-top: 1px;
      }
    }
  }

  .dropdown-menu > li > a {
    padding: 3px 0;
  }

  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:hover,
   .dropdown-menu > .active > a:focus {
    color: #333;
    text-decoration: none;
    outline: 0;
    background-color: #fff;
  }

  .btn-group.open .dropdown-toggle {
    box-shadow: none;
  }

}

.multiselect.dropdown-toggle {
  border: 1px solid #abd7e9;
  height: 40px;
  border-radius: 0;
  width: 100%;
  display: block;
  min-width: 100%;
  font-size: 15px;
  text-align: left;
  background: #fff url("/build/styles/select.png") no-repeat 100% 0px;
  .caret {
    display: none;
  }
}

.btn-select {
  border: 1px solid #abd7e9;
  height: 40px;
  border-radius: 0;
  width: 100%;
  display: block;
  min-width: 100%;
  font-size: 15px;
  text-align: left;
  background: #fff url("/build/styles/select.png") no-repeat 100% 0;
  &:focus {
    outline: none;
  }
  &:active {
    background: #fff url("/build/styles/select.png") no-repeat 100% 0;
    box-shadow: none;
  }
}
.neighborhoods-mobile {
  .select2-container {
    width: 100% !important;

  }
  .select2-container .select2-selection--multiple {
    height: 40px;
    border: 1px solid #abd7e9;
    overflow-y: auto;
  }
}


.btn-primary {
  background-color: #41c8f2;
  border-color: #41c8f2;
}