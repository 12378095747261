#datatable-ajax_wrapper {
  overflow: hidden;
}

.individual-wrapper {
  overflow: scroll;
  overflow-x: hidden;
}

@media (max-width: 1500px) {

   #datatable-ajax_wrapper {
     overflow-x: inherit;
   }

}

#datatable-ajax {
  min-width: 1500px;
}