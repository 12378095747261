@mixin breakpoint($class, $allBelow: false) {
  @if $class == xs {
    @media (max-width: $screen-xs-max) {
      @content;
    }
  }

  @else if $class == sm {
    @if $allBelow == true {
      @media (max-width: $screen-sm-max) {
        @content;
      }
    }
    @else{
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        @content;
      }
    }
  }

  @else if $class == md {
    @if $allBelow == true {
      @media (max-width: $screen-md-max) {
        @content;
      }
    }
    @else{
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        @content;
      }
    }
  }

  @else if $class == lg {
    @if $allBelow == true {
      @media only screen {
        @content;
      }
    }
    @else{
      @media (min-width: $screen-lg-min) {
        @content;
      }
    }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}
