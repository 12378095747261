.alerts-messages {
    &__table{
        border:0px;
        tr{
          height: 31px;
          border-bottom: 1px solid #f5f5f5;
            td{
              border:0px !important;
              color: $color-light-gray-7;
            }
            th {
              color: $color-light-gray-4;
            }
        }
        &__title{
            font-size: $font-size-base;
            font-weight: lighter;
            vertical-align: bottom;
            &--first{
                font-size: $font-size-big;
            }
        }
        &__column{
            &--first{
                padding-top: 0px;
                padding-bottom: 0px;
                vertical-align: middle;
            }
            &__badge{
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 21px;
                vertical-align: middle;
                &--red{
                    background: #f46471;
                }
                &--yellow{
                    background: #ffd52b;
                }
                &--green{
                    background: #73eb8f;
                }
            }
        }
    }
}
@media (min-width:1400px){
    .alerts-messages__table__column{
        &__date{
            width: 80px;
            float: left;
            display: inline-block;
        }
        &__time{
            width: calc(100% - 80px);
            float: left;
            display: inline-block;
        }
    }
}