.b-id-uploader {
  font-size: 13px;
  &__items {

  }
  &__item {
    margin-bottom: 15px;
  }
  &__type-control {
    margin-bottom: 5px;
  }
  &__upload-items {
    //
  }
  &__upload-item {
    display: inline-block;
    margin-left: 20px;
    &:first-child {
      margin-left: 0;
    }
  }
  &__uploaded-items {
    padding-top: 6px;
  }
  &__uploaded-item {
    margin-bottom: 6px;
  }
  &__upload-box-label {
    display: inline-block;
    vertical-align: middle;
  }
  &__upload-box-input {
    display: inline-block;
    vertical-align: middle;
  }
  &__add-btn {
    color: darken($pp-blue-color, 10%);
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      color: darken($pp-blue-color, 15%);
    }
  }
}

.b-co-tenants {
  font-size: 13px;
  &__items {
    margin-bottom: 10px;
  }
  &__item {
    margin-bottom: 5px;
  }
}
