/*header*/
header{
    background-color:$sky-blue;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    /*titlebar*/
    .titlebar{
        float:left;
        color:#fff;
        .titlebar__sitename{
            font-size: 18px;
            float: left;
            padding: 9px 15px 12px 0px;
            span:nth-child(1){
                font-weight: 700;
            }
            span:nth-child(2){
                font-size: 10px;
            }
        }
        .titlebar__pageview{
            float: left;
            padding: 5px 20px;
            position: relative;
        }
        .titlebar__search{
            float: left;
            padding: 5px 20px;
            color: #346bc0;
            .titlebar__search--input{
                position: relative;
                display: inline;
                margin-right: 4px;
            }
            span{
                font-size: 10px;
                cursor: pointer;
            }
        }

    }
    /*END titlebar*/
    /*menu*/
    .menu{
        ul{
            li{
                position: relative;
                display: block;
                padding: 3.5px 1.5px;
                a{
                    padding: 0;
                    width: 39px;
                    height: 39px;
                    text-align: center;
                    line-height: 37px;
                    background-color: #346bc0;
                    border-radius: 7px;
                    font-size: 20px;
                    color: #cef0ff;
                    &:hover, &:focus{
                        background-color: #346bc0;
                    }
                    .menu__list--count{
                        background: #9b1f0f;
                        position: absolute;
                        bottom: 3.5px;
                        right: 3.5px;
                        font-size: 10px;
                        height: 15px;
                        width: 15px;
                        border-radius: 50%;
                        line-height: 14px;
                        display: inline-block;
                    }
                }
                &:last-child{
                    padding: 3.5px 4px;
                    margin-right: 2px;
                    a{
                        width: auto;
                        img{
                            height: 31px;
                            border-radius: 7px;
                            margin-top: -3px;
                            display: inline-block;
                            margin-left: 4px;
                        }
                        span{
                            padding: 0 20px 0 15px;
                        }
                    }
                }
            }
        }
    }
    /*    .menu--mobile{
            display:none;
        }
        @media (max-width:767px){
            .menu--mobile{
                display:block;
            }
            .menu{
                .nav{
                    display:none;
                }
            }
        }*/
    /*END menu*/
}
/*header mobile*/
.menu .navbar-nav>li {
    float: left !important;
}
.menu .navbar-right {
    float: right!important;
    margin: 0 -15px 0 0;
}
.titlebar__pageview--mobile{
    display:none;
}
.titlebar__search--mobile{
    display:none;
}
@media (max-width:1120px){
    header{
        .titlebar__pageview {
            display:none;
        }
        .titlebar__search {
            display:none;
        }
        .titlebar__pageview--mobile{
            display: block;
            float: left;
            background: #cef0ff;
            color: #346bc0;
            margin: 5px 2px;
            width: 36px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            border-radius: 7px;
            font-size: 20px;
            cursor: pointer;
        }
        .titlebar__search--mobile{
            display: block;
            float: left;
            background: #cef0ff;
            color: #346bc0;
            margin: 5px 2px;
            width: 36px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            border-radius: 7px;
            font-size: 20px;
            cursor: pointer;
        }
        .titlebar{
            .titlebar__sitename{
                padding: 9px 10px 12px 0px;
            }
            .titlebar__pageview {
                padding: 5px 5px;
                .fa-eye {
                    left: 20px;
                }
                .fa-caret-down {
                    right: 20px;
                }
            }
            .titlebar__search {
                padding: 5px 5px;
            }
        }
    }
    /*END header mobile*/
}
/*END header*/
/*aside navbar*/
.aside-navbar{
    position: fixed;
    top: 47px;
    left: 0;
    bottom: 0;
    background-color: #346bc0;
    z-index: 99;
    ul {
        margin: 15px 0;
        li{
            a{
                color: #fff;
                text-align: center;
                font-size: 10px;
                padding: 5px 10px;
                float: left;
                width: 100%;
                i{
                    font-size: 19px;
                    display: block;
                }
                span{
                    margin-top: -2px;
                    display: block;
                }
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}
@media(max-width:767px){
    .aside-navbar ul li a{
        padding: 5px 2px;
    }
}
/*END aside navbar*/
/*main content*/
.main-content{
    padding-left: 69px;
    margin-top: 47px;
}
/*comps*/
.comps-section{
    color: #346bc0;
    h1{
        margin: 15px 0;
        font-size: 24px;
        font-weight: 200;
        i{
            font-size: 20px;
        }
        i:nth-child(1){
            margin: 0 6px 0 10px;
        }
    }
    .comps-section__comps--outer{
        max-height: calc(100vh - 109px);
        overflow-y: scroll;
    }
    .table tr td, .table tr th{
        border-top: 0;
    }
    .comps{
        font-weight: 300;
        font-size: 12px;
        th{
            text-align: center;
            font-weight: 300;
            font-size: 12px;
            border-bottom: 0;
            padding: 0;
        }
        td{
            width:30%;
            vertical-align: middle;
            padding: 2px 4px 0 4px;
            & > div{
                border: 1px solid #000;
            }

        }
        .comps__row{
            & > td:nth-child(1){
                div{
                    display: inline-block;
                    padding-left: 13%;
                    width: 100%;
                }
                i{
                    float: left;
                    font-size: 21px;
                }
                .address{
                    width: calc(100% - 35px);
                    margin-left: 10px;
                    float: left;
                    padding: 0;
                    span:nth-child(1){
                        display: block;
                        text-transform: capitalize;
                        margin-top: -2px;
                        line-height: 12px;
                    }
                    span:nth-child(2){
                        font-size: 8px;
                        text-transform: uppercase;
                        display: block;
                    }
                }

            }
            & > td:nth-child(2), & > td:nth-child(3){
                .table{
                    margin-bottom: 0;
                    tr{
                        td{
                            padding: 1px 5px 1px 5px;
                            width: 49%;
                        }
                        td:nth-child(1){
                            text-transform: uppercase;
                            span{
                                font-size: 7px;
                                font-weight: 500;
                            }
                        }
                        td:nth-child(2){
                            span{
                                font-size: 7px;
                                font-weight: 500;
                                text-transform: capitalize;
                                margin-left: -3px;
                            }
                        }
                        td:nth-child(3){
                            text-align: right;
                        }
                    }
                }
            }
            & > td:nth-child(4){
                width:10%;
                font-size: 20px;
                position: relative;
                i:nth-child(1){
                    margin: 0 6px 0 4px;
                }
                i{
                    cursor: pointer;
                }
                .comps__row--share--popup{
                    display:none;
                    position: absolute;
                    top: 49px;
                    right: 14px;
                    width: 300px;
                    background: #cef0ff;
                    border: 1px solid #346bc0;
                    padding: 7px 10px 10px 10px;
                    font-size: 14px;
                    line-height: 15px;
                    z-index: 99;
                    h3{
                        i{
                            margin: 0;
                        }
                        margin-top: 0;
                        font-weight: 300;
                    }
                    .share-popup__title{
                        width:50%;
                        float:left;
                    }
                    .share-popup__description{
                        width:50%;
                        float:left;
                    }
                    .btn-blue{
                        float: right;
                        margin-top: 5px;
                        background-color: #346bc0;
                        border-radius: 0;
                        color: #fff;
                        font-size: 12px;
                        padding: 5px 37px;
                    }
                }
            }
        }
    }
}
@media(max-width:767px){
    .comps-section{
        .comps{
            & > thead{
                display:none;
            }
            & > tbody{
                width: 99%;
                display: block;
                & > tr{
                    width: 100%;
                    display: block;
                    margin-bottom: 10px;
                    & > td{
                        width: 100%;
                        display: block;
                        & > div{
                            padding: 5px;
                        }
                    }
                    & > td:nth-child(1){
                        & > div{
                            padding: 20px;
                        }
                    }
                    & > td:nth-child(4){
                        text-align: center;
                        width: 100% !important;
                    }
                }
            }
        }
        .comps__row--share--popup{
            top: 32px !important;
            width: 100% !important;
            right: 0 !important;
            text-align: left;
        }
    }
}
/*END comps*/
/*Right sidebar*/
.right-sidebar{
    /*Messages*/
    .messages{
        margin-top: 12px;
        table{
            tbody{
                td:nth-child(1){
                    font-size: 12px;
                    width: 40%;
                }
                td{
                    font-size: 10px;
                    width: 16%;
                }
                td:nth-child(3){
                    span{
                        margin-left: 15px;
                    }
                }
                tr.messages__row--box-red{
                    td:nth-child(1){
                        span{
                            display: inline-block;
                            width: 15px;
                            height: 15px;
                            background: #f3676f;
                            vertical-align: bottom;
                            margin-right: 5px;
                        }
                    }
                }
                tr.messages__row--box-orange{
                    td:nth-child(1){
                        span{
                            display: inline-block;
                            width: 15px;
                            height: 15px;
                            background: #feb700;
                            vertical-align: bottom;
                            margin-right: 5px;
                        }
                    }
                }
                tr.messages__row--box-green{
                    td:nth-child(1){
                        span{
                            display: inline-block;
                            width: 15px;
                            height: 15px;
                            background: #3ab44a;
                            vertical-align: bottom;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

    }
    /*END Messages*/
    /*Estate news*/
    .estate-news{
        margin-top: 12px;
        table{
            thead{
                tr{
                    th:last-child{
                        text-align: right;
                        color: #346bc0;
                        font-weight: 600;
                        font-size: 9px;
                        vertical-align: middle;
                        .fa-cogs{
                            padding-left: 10px;
                        }
                    }
                }
            }
            tbody{
                tr:nth-child(2n+1){
                    background-color: #fff;
                }
                tr:first-child{
                    td{
                        padding-top: 8px;
                    }
                }
                tr{
                    td{
                        padding: 0px 11px 8px 11px;
                        font-weight: 600;
                        position: relative;
                        img{
                            display: inline-block;
                            float: left;
                            margin-right: 6px;
                            width: 57px;
                        }
                        p{
                            display: inline-block;
                            float: left;
                            margin-bottom: 13px;
                            width: calc(100% - 66px);
                            line-height: 14px;
                        }
                        span{
                            font-size: 9px;
                            font-weight: 300;
                            position: absolute;
                            bottom: 6px;
                            right: 8px;
                        }
                    }
                }
                tr:last-child{
                    td{
                        text-align: right;
                        font-size: 9px;
                        font-weight: 300;
                    }
                }
            }
        }
    }
    @media (max-width:767px){
        .estate-news{
            table{
                display: inline-block;
                width: 100%;
                thead{
                    display: block;
                    width: 100%;
                    border-bottom: 1px solid #37b1f3;
                    tr{
                        display: block;
                        width: 100%;
                        th:first-child{
                            display: inline-block;
                            border: 0;
                        }
                        th:last-child{
                            display: inline-block;
                            float: right;
                            border: 0;
                            margin-top: 6px;
                        }
                    }
                }
                tbody{
                    border-top: 0;
                    tr{
                        display: inline-block;
                        width: 100%;
                        td{
                            display: inline-block;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    /*END Estate news*/
    /*Lease ending*/
    .lease-ending{
        margin-top: 12px;
        thead{
            tr{
                th{
                    font-size: 13px;
                }
                th:last-child{
                    text-align: right;
                    .fa-cogs{
                        vertical-align: middle;
                    }
                }
                th:nth-child(5){
                    text-align: center;
                }
            }
        }
        tbody{
            tr{
                td:nth-child(5){
                    text-align: center;
                }
            }
            tr.lease-ending__row--box-green td:last-child{
                span{
                    background-color: #3ab44a;
                    padding: 1px 3px;
                    text-transform: uppercase;
                    font-size: 13px;
                }
            }
            tr.lease-ending__row--box-yellow td:last-child{
                span{
                    background-color: #ebec00;
                    padding: 1px 3px;
                    text-transform: uppercase;
                    font-size: 13px;
                }
            }
        }
    }
    /*END Lease ending*/
}
/*END Right sidebar*/
/*END main content*/
@media(max-width: 767px){
    .main-content{
        padding-right: 8px;
        padding-left: 54px;
    }
    .comps-section{
        padding: 0;
    }
    .right-sidebar{
        padding: 0 4px;
    }
}
/*Responsive tables*/
@media (max-width: 767px) {
    #no-more-tables thead{
        display: none;
    }
    #no-more-tables table,
    #no-more-tables tbody,
    #no-more-tables th,
    #no-more-tables td,
    #no-more-tables tr {
        display: block;
    }
    #no-more-tables thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    #no-more-tables td {
        border: none;
        position: relative;
        padding-left: 50%;
        white-space: normal;
        text-align:left;
        width: auto;
    }
    #no-more-tables td:before {
        position: absolute;
        top: 3px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align:left;
        font-weight: bold;
    }
    #no-more-tables td:before { content: attr(data-title); }
}
/*END Responsive tables*/
