.violations{
    &__table{
        width: 100%;
        overflow-x: scroll;
        table{
            min-width: 800px;
            width: 100%;
            text-align: left;
        }
        tbody{
            font-size: 13px;
            &:nth-child(2n){
                td{
                    background-color: #f4f4f4;
                }
            }
            td{
                padding: 10px 0px;
            }
        }
        &__tbody{
            .fa-minus-square-o{
                display: inline-block;
            }
            .fa-plus-square-o{
                display: none;
            }
            tr:nth-child(2){
                display: table-row;
            }
            &.collapsed{
                tr:nth-child(2){
                    display: none;
                }
                .fa-minus-square-o{
                    display: none;
                }
                .fa-plus-square-o{
                    display: inline-block;
                }
            }
            .bold{
                font-weight: bold;
            }
            .total{
                border-top: 1px solid #000;
            }
        }
        &__expand{
            &-child{
                padding: 10px;
                fieldset{
                    border: 1px solid #CCC;
                    margin: 0 3px 9px 2px;
                    padding: 6px 12px 10px;
                    legend{
                        width: auto;
                        padding: 0 5px;
                        font-size: 14px;
                        text-transform: uppercase;
                        border-bottom: none;
                        margin-bottom: 0px;
                    }
                }
                td{
                    border-bottom: 2px solid #aaa;
                }
            }
            &-parent{
                td:nth-child(2){
                    i{
                        text-lign:center !important;
                        color: #f6cc23 !important;
                        cursor: pointer;
                        font-size: 15px;
                    }
                }
                i{
                    font-size: 14px;
                    padding: 0 4px;
                }
            }
        }
        &__head{
            height: 40px;
            text-transform: uppercase;
            th{
                font-weight: 300;
                i{
                    font-size: 14px;
                    padding: 0 4px;
                }
            }
        }
        &__box{
            display: inline-block;
            &-label{
                width: 70px;
                font-weight: bold;
            }
            &-left{
                float:left;
                width: auto;
                max-width:70%;
            }
            &-right{
                float:right;
                width: auto;
                max-width:30%;
            }
            &-border{
                boder: solid 1px #ccc;
                display: inline-block;
            }
            &-bg{
                color: white;
                display: inline-block;
                text-align: center;
                padding: 3px 0px;
                line-height: 16px;
                &--green{
                    background-color:#86ca5d;
                }
                &--gray{
                    background-color:#b3b3b3;
                }
                &--orange{
                    background-color:#ff8100;
                }
                &--yellow{
                    background-color:#FF6;
                    color:#000;
                }
            }
        }
    }
}


.expand-child table td {
    border-top: 1px solid #DDD;
    padding: 9px 7px;
}

.pp-datatable .agency .label {
    display: block;
    max-width: 250px;
    text-shadow: none !important;
    font-size: 12px;
    font-weight: 600;
    padding: 3px 6px 2px;
    color: #5B5B5B;
}

div.total {
    border-top: 1px solid;
    padding-top: 4px;
}

span.dohmh {
    background: #50738F;
}

span.fdny {
    background: #B52C1A;
}

span.dsny {
    background: #19b552;
}

span.dot {
    background: #096;
}
span.dep {
    background: #009D55;
}
span.dof {
    background: #E56D1E;
}

span.hpd {
    background: #00AEC6;
}
