.notification {
  color: #97a8b5;
  padding: 5px 0px;
  display: inline-block;
  width: 100%;
  text-transform: uppercase;
  &__success {
    //background: $color-green;
    &__btn,
    &__child {
      vertical-align: top;
      display: inline-block;
      margin-right: 15px;
    }
  }
}

@media (max-width: 767px) {
  .notification {
    &__success {
      text-align: center;
      &__btn {
        margin-bottom: 10px;
        width: 100%;
        font-size: 16px;
        padding: 8px 16px;
      }
      &__child {
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }
}
