/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$advertise-active-name: 'advertise-active';
$advertise-active-x: 78px;
$advertise-active-y: 0px;
$advertise-active-offset-x: -78px;
$advertise-active-offset-y: 0px;
$advertise-active-width: 27px;
$advertise-active-height: 23px;
$advertise-active-total-width: 175px;
$advertise-active-total-height: 128px;
$advertise-active-image: '../img/sprite.png';
$advertise-active: (78px, 0px, -78px, 0px, 27px, 23px, 175px, 128px, '../img/sprite.png', 'advertise-active', );
$advertise-name: 'advertise';
$advertise-x: 78px;
$advertise-y: 33px;
$advertise-offset-x: -78px;
$advertise-offset-y: -33px;
$advertise-width: 27px;
$advertise-height: 23px;
$advertise-total-width: 175px;
$advertise-total-height: 128px;
$advertise-image: '../img/sprite.png';
$advertise: (78px, 33px, -78px, -33px, 27px, 23px, 175px, 128px, '../img/sprite.png', 'advertise', );
$arrow-down-name: 'arrow-down';
$arrow-down-x: 153px;
$arrow-down-y: 80px;
$arrow-down-offset-x: -153px;
$arrow-down-offset-y: -80px;
$arrow-down-width: 15px;
$arrow-down-height: 10px;
$arrow-down-total-width: 175px;
$arrow-down-total-height: 128px;
$arrow-down-image: '../img/sprite.png';
$arrow-down: (153px, 80px, -153px, -80px, 15px, 10px, 175px, 128px, '../img/sprite.png', 'arrow-down', );
$calendar-active-name: 'calendar-active';
$calendar-active-x: 33px;
$calendar-active-y: 71px;
$calendar-active-offset-x: -33px;
$calendar-active-offset-y: -71px;
$calendar-active-width: 23px;
$calendar-active-height: 26px;
$calendar-active-total-width: 175px;
$calendar-active-total-height: 128px;
$calendar-active-image: '../img/sprite.png';
$calendar-active: (33px, 71px, -33px, -71px, 23px, 26px, 175px, 128px, '../img/sprite.png', 'calendar-active', );
$calendar-name: 'calendar';
$calendar-x: 0px;
$calendar-y: 71px;
$calendar-offset-x: 0px;
$calendar-offset-y: -71px;
$calendar-width: 23px;
$calendar-height: 26px;
$calendar-total-width: 175px;
$calendar-total-height: 128px;
$calendar-image: '../img/sprite.png';
$calendar: (0px, 71px, 0px, -71px, 23px, 26px, 175px, 128px, '../img/sprite.png', 'calendar', );
$dashboard-active-name: 'dashboard-active';
$dashboard-active-x: 62px;
$dashboard-active-y: 107px;
$dashboard-active-offset-x: -62px;
$dashboard-active-offset-y: -107px;
$dashboard-active-width: 21px;
$dashboard-active-height: 21px;
$dashboard-active-total-width: 175px;
$dashboard-active-total-height: 128px;
$dashboard-active-image: '../img/sprite.png';
$dashboard-active: (62px, 107px, -62px, -107px, 21px, 21px, 175px, 128px, '../img/sprite.png', 'dashboard-active', );
$dashboard-name: 'dashboard';
$dashboard-x: 0px;
$dashboard-y: 107px;
$dashboard-offset-x: 0px;
$dashboard-offset-y: -107px;
$dashboard-width: 21px;
$dashboard-height: 21px;
$dashboard-total-width: 175px;
$dashboard-total-height: 128px;
$dashboard-image: '../img/sprite.png';
$dashboard: (0px, 107px, 0px, -107px, 21px, 21px, 175px, 128px, '../img/sprite.png', 'dashboard', );
$help-active-name: 'help-active';
$help-active-x: 35px;
$help-active-y: 36px;
$help-active-offset-x: -35px;
$help-active-offset-y: -36px;
$help-active-width: 25px;
$help-active-height: 25px;
$help-active-total-width: 175px;
$help-active-total-height: 128px;
$help-active-image: '../img/sprite.png';
$help-active: (35px, 36px, -35px, -36px, 25px, 25px, 175px, 128px, '../img/sprite.png', 'help-active', );
$help-name: 'help';
$help-x: 0px;
$help-y: 36px;
$help-offset-x: 0px;
$help-offset-y: -36px;
$help-width: 25px;
$help-height: 25px;
$help-total-width: 175px;
$help-total-height: 128px;
$help-image: '../img/sprite.png';
$help: (0px, 36px, 0px, -36px, 25px, 25px, 175px, 128px, '../img/sprite.png', 'help', );
$location-name: 'location';
$location-x: 153px;
$location-y: 100px;
$location-offset-x: -153px;
$location-offset-y: -100px;
$location-width: 10px;
$location-height: 13px;
$location-total-width: 175px;
$location-total-height: 128px;
$location-image: '../img/sprite.png';
$location: (153px, 100px, -153px, -100px, 10px, 13px, 175px, 128px, '../img/sprite.png', 'location', );
$message-active-name: 'message-active';
$message-active-x: 153px;
$message-active-y: 0px;
$message-active-offset-x: -153px;
$message-active-offset-y: 0px;
$message-active-width: 22px;
$message-active-height: 16px;
$message-active-total-width: 175px;
$message-active-total-height: 128px;
$message-active-image: '../img/sprite.png';
$message-active: (153px, 0px, -153px, 0px, 22px, 16px, 175px, 128px, '../img/sprite.png', 'message-active', );
$message-name: 'message';
$message-x: 153px;
$message-y: 26px;
$message-offset-x: -153px;
$message-offset-y: -26px;
$message-width: 22px;
$message-height: 16px;
$message-total-width: 175px;
$message-total-height: 128px;
$message-image: '../img/sprite.png';
$message: (153px, 26px, -153px, -26px, 22px, 16px, 175px, 128px, '../img/sprite.png', 'message', );
$search-active-name: 'search-active';
$search-active-x: 31px;
$search-active-y: 107px;
$search-active-offset-x: -31px;
$search-active-offset-y: -107px;
$search-active-width: 21px;
$search-active-height: 21px;
$search-active-total-width: 175px;
$search-active-total-height: 128px;
$search-active-image: '../img/sprite.png';
$search-active: (31px, 107px, -31px, -107px, 21px, 21px, 175px, 128px, '../img/sprite.png', 'search-active', );
$search-top-name: 'search-top';
$search-top-x: 153px;
$search-top-y: 52px;
$search-top-offset-x: -153px;
$search-top-offset-y: -52px;
$search-top-width: 18px;
$search-top-height: 18px;
$search-top-total-width: 175px;
$search-top-total-height: 128px;
$search-top-image: '../img/sprite.png';
$search-top: (153px, 52px, -153px, -52px, 18px, 18px, 175px, 128px, '../img/sprite.png', 'search-top', );
$search-name: 'search';
$search-x: 93px;
$search-y: 107px;
$search-offset-x: -93px;
$search-offset-y: -107px;
$search-width: 21px;
$search-height: 21px;
$search-total-width: 175px;
$search-total-height: 128px;
$search-image: '../img/sprite.png';
$search: (93px, 107px, -93px, -107px, 21px, 21px, 175px, 128px, '../img/sprite.png', 'search', );
$tennants-active-name: 'tennants-active';
$tennants-active-x: 115px;
$tennants-active-y: 30px;
$tennants-active-offset-x: -115px;
$tennants-active-offset-y: -30px;
$tennants-active-width: 23px;
$tennants-active-height: 24px;
$tennants-active-total-width: 175px;
$tennants-active-total-height: 128px;
$tennants-active-image: '../img/sprite.png';
$tennants-active: (115px, 30px, -115px, -30px, 23px, 24px, 175px, 128px, '../img/sprite.png', 'tennants-active', );
$tennants-name: 'tennants';
$tennants-x: 115px;
$tennants-y: 64px;
$tennants-offset-x: -115px;
$tennants-offset-y: -64px;
$tennants-width: 23px;
$tennants-height: 24px;
$tennants-total-width: 175px;
$tennants-total-height: 128px;
$tennants-image: '../img/sprite.png';
$tennants: (115px, 64px, -115px, -64px, 23px, 24px, 175px, 128px, '../img/sprite.png', 'tennants', );
$vendors-active-name: 'vendors-active';
$vendors-active-x: 115px;
$vendors-active-y: 0px;
$vendors-active-offset-x: -115px;
$vendors-active-offset-y: 0px;
$vendors-active-width: 28px;
$vendors-active-height: 20px;
$vendors-active-total-width: 175px;
$vendors-active-total-height: 128px;
$vendors-active-image: '../img/sprite.png';
$vendors-active: (115px, 0px, -115px, 0px, 28px, 20px, 175px, 128px, '../img/sprite.png', 'vendors-active', );
$vendors-name: 'vendors';
$vendors-x: 66px;
$vendors-y: 71px;
$vendors-offset-x: -66px;
$vendors-offset-y: -71px;
$vendors-width: 28px;
$vendors-height: 20px;
$vendors-total-width: 175px;
$vendors-total-height: 128px;
$vendors-image: '../img/sprite.png';
$vendors: (66px, 71px, -66px, -71px, 28px, 20px, 175px, 128px, '../img/sprite.png', 'vendors', );
$website-site-name: 'website-site';
$website-site-x: 39px;
$website-site-y: 0px;
$website-site-offset-x: -39px;
$website-site-offset-y: 0px;
$website-site-width: 29px;
$website-site-height: 26px;
$website-site-total-width: 175px;
$website-site-total-height: 128px;
$website-site-image: '../img/sprite.png';
$website-site: (39px, 0px, -39px, 0px, 29px, 26px, 175px, 128px, '../img/sprite.png', 'website-site', );
$website-name: 'website';
$website-x: 0px;
$website-y: 0px;
$website-offset-x: 0px;
$website-offset-y: 0px;
$website-width: 29px;
$website-height: 26px;
$website-total-width: 175px;
$website-total-height: 128px;
$website-image: '../img/sprite.png';
$website: (0px, 0px, 0px, 0px, 29px, 26px, 175px, 128px, '../img/sprite.png', 'website', );
$spritesheet-width: 175px;
$spritesheet-height: 128px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($advertise-active, $advertise, $arrow-down, $calendar-active, $calendar, $dashboard-active, $dashboard, $help-active, $help, $location, $message-active, $message, $search-active, $search-top, $search, $tennants-active, $tennants, $vendors-active, $vendors, $website-site, $website, );
$spritesheet: (175px, 128px, '../img/sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
