/*
  HTML template for checkbox should be like this:
  <label class="e-checkbox">
    <input type="checkbox">
    <span></span>
    Checkbox label text
  </label>
*/


.e-checkbox {
  position: relative;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 400;
  // @include transition(color 0.3s ease 0s);
  input[type="checkbox"], input[type="radio"] {
    display: none;
  }
  input[type="checkbox"] ~ span:before, input[type="radio"] ~ span:before {
    font-family: FontAwesome;
    content: "\f096";
    display: inline-block;
    font-size: 18px;
    color: #6b8091;
    width: 22px;
    line-height: 24px;
    vertical-align: top;
    cursor: pointer;
    opacity: .8;

    // position: absolute;
    // left: 0;
    // top: -2px;
  }
  &:hover input[type="checkbox"] ~ span:before, input[type="radio"] ~ span:before {
    opacity: 1;
  }
  input[type="checkbox"]:checked ~ span:before, input[type="radio"]:checked ~ span:before {
    content: "\f046";
    color: #1ca401;
  }
  &.-w180 {
    width: 180px;
  }
  &.-w160 {
    width: 160px;
  }
  &.-left {
    text-align: left;
  }
  &.-up {
    text-transform: uppercase;
  }
  &.-nobmargin {
    margin-bottom: 0;
  }
  &.-formfit {
    position: relative;
    margin-top: 13px;
    font-size: 12px;
  }
}

.e-inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  + label {
    // max-width: 80%;
    max-width: 160px;
    font-size: 1.25rem;
    /* 20px */
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.55rem 1.25rem;
    vertical-align: top;
    /* 10px 20px */
  }
  &:focus + label,
  &.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
  + label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
  }
}
.e-inputfile.-general {
  + label {
    color: #fff;
    background-color: $pp-blue-color;
    margin-bottom: 0;
  }
  &:focus + label,
  &.has-focus + label,
  + label:hover {
    background-color: darken($pp-blue-color, 5%);
  }
}
.e-inputfile.-wide {
  + label {
    max-width: none;
  }
}
.e-inputfile.-block {
  + label {
    max-width: none;
    width: 100%;
    text-align: center;
  }
}
.e-inputfile.-up {
  + label {
    text-transform: uppercase;
  }
}

.e-tbl-btn {
  color: #333333;
  border: none;
  background-color: transparent;
  padding: 0 2px;
  font-size: 18px;
  margin-left: 5px;
  margin-right: 5px;
  opacity: .85;
  &:hover {
    opacity: 1;
  }
}

.e-label {
  display: inline-block;
  font-size: 85%;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 5px;
}

.e-inputfile-label {
  width: 100%;
  text-align: center;
  font-size: 1.25rem;
  /* 20px */
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.55rem 1.25rem;
  vertical-align: top;

  color: #fff;
  background-color: #41c8f2;
  margin-bottom: 0;
}
