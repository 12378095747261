.control-sidebar {
    padding-top: 86px;
    @include breakpoint(xs) {
        padding-top: 106px;
    }
}

.control-sidebar-open {
    .control-sidebar-toggler {
        background: #f9fafc;
        a:active {
            background: transparent !important;
        }
        a > i {
            color: $color-blue-1;
        }
    }

    .no-border-box{
        border:0px;
    }

    .control-sidebar-tabs i {
        font-size: 18px;
    }
}
.control-sidebar-light {
    background: #fff;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    & + .control-sidebar-bg  {
        background: #fff;
    }
}
.news-block {
    .box-header,
    .box-body {
        padding-right: 0;
        padding-left: 0;
    }
}
body.modal-open{
    .control-sidebar{
        display: none;
        &-bg{
            display: none;
        }
    }
}
