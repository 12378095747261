/** Making the fonts smaller on the whole theme **/
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import './bootstrap/variables';

// Overrides for vendor styles
@import './overrides/leaflet';
@import './overrides/emojione';
@import './overrides/slick';
@import './overrides/vuetable';
@import './semantic/styles';



body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.skin-blue .wrapper, .skin-blue .main-sidebar, .skin-blue .left-side {
    background-color: #525f6a;
}

.main-header {
    padding-top: 20px;
}
.skin-blue .main-header .navbar {
    background-color: #41c8f2;
}

.control-sidebar-bg, .control-sidebar {
    width: 37%;
}

@media (max-width: $screen-md-max) {
    .control-sidebar-open .content-wrapper, .control-sidebar-open .right-side, .control-sidebar-open .main-footer {
        margin-right: 0 !important;
    }
}

@media (min-width: $screen-lg-min) {
    .control-sidebar-open .content-wrapper, .control-sidebar-open .right-side, .control-sidebar-open .main-footer {
        margin-right: 37%;
    }
}

@media (max-width: $screen-xs-max) {
    .control-sidebar {
        right: -95%;
        width: 95%;
    }
    .alerts-messages__table__title.alerts-messages__table__title--first {
        font-size: 12px !important;
    }
    .box-header .box-title {
        font-size: 16px !important;
    }
    .control-sidebar > .tab-content {
        padding: 10px 10px;
    }
    .lease-endings__table {
        font-size: 8px !important;
    }
    .table-condensed > tbody > tr > td {
        padding: 5px !important;
    }
    .lease-endings__table__title--first {
        font-size: 11px !important;
    }
}
