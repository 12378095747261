@import "../sprite.scss";

$custom-sb-width: 115px;
$custom-sb-menu-item-height: 24px;
$icon-size: 20px;



.sidebar-menu {
    min-height: 0;
    max-height: 100vh;
    height: calc(100% - 86px);
    overflow-y: auto;
    overflow-x: hidden;
 

    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1010;
    @include breakpoint(xs, true) {
        margin-top: 20px;
        height: calc(100% - 106px);
    }


  li.parent:hover > .dd-wrapper {
    display: block;
  }
  li.parent {
    position: static !important;
    .dd-wrapper {
      position: absolute;
      z-index: 10;
      display: none;
    }

    ul {
      width: 200px;
       background-color: antiquewhite;
      overflow-x: hidden;
       
    }
  }
}


// Fixes for AdminLTE
.content-wrapper, .right-side, .main-footer {
    margin-left: $custom-sb-width;

    // Fix: because it hurts the child "fixed" positioned elements
    // http://stackoverflow.com/questions/15194313
    transform: none !important;
    @include breakpoint(xs, true) {
        margin-left: 0 !important;
    }
}

.main-sidebar {
    position: fixed;
    padding-top: 0 !important;
    top: 86px;
    left: 0;
    bottom: 0;
    ::-webkit-scrollbar {
        width: 12px;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }

}

.sidebar-collapse.sidebar-mini .main-sidebar,
.main-sidebar {
    width: $custom-sb-width !important;
    .sidebar-menu {
        width: $custom-sb-width !important;
        text-align: center;
        li {
            min-height: $custom-sb-menu-item-height;
            a {
                padding: 16px 15px 16px 12px;
                border-bottom: 1px solid #44505a;

                span {
                    display: block !important;
                }

                span.pull-right-container {
                    display: none !important;
                }
            }
        }

        > li:hover {
           // width: $custom-sb-width;
            //height: 60px;
            > a {

                > i {
                    font-size: 24px;
                }
            }
            //span:not(.pull-right-container) {
            //    left: $custom-sb-width;
            //    line-height: $custom-sb-menu-item-height;
            //    text-align: left;
            //    font-size: 14px;
            //}
            ul.treeview-menu {
                left: $custom-sb-width;
                line-height: 14px;
                text-align: left;
                top: 48px;
                padding-bottom: 12%;
                a > i {
                    width: 12px !important;
                }
            }
        }
    }
}

/** Custom styles for the left sidebar and it's elements in the collapsed state**/
.sidebar-collapse.sidebar-mini,
.sidebar-open{
    .content-wrapper {
        margin-left: $custom-sb-width !important;
        @include breakpoint(xs, true) {
            margin-left: 0 !important;
        }
        &.no-auth-wrapper {
            margin-left: 0 !important;
        }
    }

    .main-header {
        .logo {
            width: 230px;
        }

        .navbar {
            margin-left: 230px;
        }
    }

    footer.main-footer {
        margin-left: $custom-sb-width !important;
    }
}

.sidebar-menu {
    li {

        i.pull-right{
            font-size: inherit !important;
            width: inherit !important;
        }
        a {
            color: #93aabc;
            span {
                font-size: 14px;
                color: #93aabc;
            }
        }
    }
    .treeview-menu {
        li {
            a {
                font-size: 11px;
                i {
                    font-size: 12px;
                }
            }

        }
    }
}
.sidebar-menu:hover {
    overflow-y: auto;
    overflow-x: visible;
}
@media (max-width: 767px){
    .sidebar-open .content-wrapper {
        margin-left: 0px !important;
    }
    .sidebar-collapse.sidebar-mini:not(.sidebar-open){
        .content-wrapper, .main-footer {
            margin-left: 0px !important;
        }
    }
}


.skin-blue .sidebar-menu > li:hover > a, .skin-blue .sidebar-menu > li.active > a {
    color: #fff;
    background: none;
    border-left-color: #525f6a;
}

.sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span:not(.pull-right), .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
    display: block !important;
    position: relative;
    width: auto;
    left: initial;
    color: #41c8f2;
}

@media (min-width: 768px) {
    .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span {
        padding: 0;
        margin-left: 0;
    }
}

.sidebar-mini.sidebar-collapse .sidebar-menu > li:hover {
    .icon-pp-dashboard {
        @include sprite($dashboard-active);
    }

    .icon-pp-search {
        @include sprite($search-active);
    }

    .icon-pp-message {
        @include sprite($message-active);
    }

    .icon-pp-tennants {
        @include sprite($tennants-active);
    }

    .icon-pp-properties {
        background-position: -115px 0px;
        background-image: url(../img/sprite.png);
    }

    .icon-pp-properties-gg {
        background-image: url(/img/gg_small.png);
    }
}
