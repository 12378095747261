.neighborhood-category {
  padding: 0;
  margin: 6px 0 0;
  &__element {
    display: inline-block;
    margin-left: 15px;
    &.active {
      a {
        color: $color-blue-1;
      }
    }
    a {
      color: $color-dark-blue;
      text-decoration: none;
      font-size: 13px;
      &:hover,
      &.active{
        color: $color-blue-1;
      }
    }
  }
}

.neighborhood-list {
  &__select-all {
    font-size: 14px;
    margin: 0 0 10px 0;
    &-title {
      padding-left: 8px;
      font-weight: normal;
    }
  }
  &__container {
    .neighborhoods-tree:first-child {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      -webkit-column-gap: 0;
      -moz-column-gap: 0;
      column-gap: 0px;
      padding: 0 !important;
      margin: 0 !important;
      border-left: none;
    }
  }
}

.neighborhoods-tree {
  padding: 0 0 0 12px;
  margin: 0 0 0 6px;
  font-size: 14px;
  border-left: 1px dotted #ccc;
  &__element {
    // margin: 0 0 5px 0;
    margin: 0;
    list-style: none;
  }
  &__element-group {
    margin: 0 0 5px 0;
  }
  &__title {
    display:inline;
    padding-left: 8px;
    margin: 0 !important;
    font-weight: normal;
    color: $color-light-gray-6;
    font-size: 14px;
    font-family: Poppins, ALL CAPS;
  }
}
