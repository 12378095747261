@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,700);
#gg-landing {
	font-family: 'Poppins', sans-serif;
	padding: 2%;
	font-size: 16px;
	overflow-x: hidden;
	h1 {
		font-size: 38px;
		font-weight: 300;
		margin-bottom: 3px;
	}
	h2 {
		font-size: 26px;
		font-weight: 300;
		margin-bottom: 0px;
	}
	h3 {
		font-size: 20px;
		font-weight: 300;
		color: #666666;
		margin-bottom: 40px;
	}
	h4	{
		font-size: 16px;
		font-weight: 700;
		margin-top: 45px;
	}
	.gray-box {
		background: #dddddd;
		padding: 2%;
		border: none;
		font-size: 18px;
	}
	.intercom {
		margin-bottom: -165px;
	}
	.mobile-intercom {
		margin: 10px auto;
	}
	p {
		line-height: 30px;
	}
	li {
		line-height: 30px;
	}
	.trd {
		margin: 14px auto 0px auto;
	}
}