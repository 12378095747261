.card{
    -webkit-box-shadow: 2px 2px 3px 0px rgba(119,119,119,1);
    -moz-box-shadow: 2px 2px 3px 0px rgba(119,119,119,1);
    box-shadow: 2px 2px 3px 0px rgba(119,119,119,1);
    margin-top: 20px;
    margin-right: 20px;
    width: 270px;
    float: left;
    &__person{
        &--purple{
            background-color: #a962a6;
        }
        &--blue{
            background-color: #27a4e5;
        }
        &--yellow{
            background-color: #bba926;
        }
        &--green{
            background-color: #2f831e;
        }
        &--orange{
            background-color: #c0731b;
        }
        &--pink{
            background-color: #c2358b;
        }
        padding: 10px;
        position: relative;
        color: #fff;
        min-height: 120px;
        &__photo{
            float: left;
            width: 100px;
            height: 100px;
            border-radius: 10px;
            overflow: hidden;
            img{
                width: 100%;
            }
        }
        &__bio{
            padding-left: 110px;
            text-transform: uppercase;
            &__name{
                font-size: 24px;
                line-height: 24px;
                padding-right: 30px;
                margin-bottom: 0px;
            }
            &__info{
                font-size: 13px;
                padding-top: 0px;
                line-height: 16px;
                margin-bottom: 0px;
                &--with-top-padding{
                    padding-top: 20px;
                }
                a{
                    color: #fff;
                }
            }
        }
        &__verified{
            position: absolute;
            top: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
            background: url(/img/verified.png) no-repeat;
            background-size: cover;
        }
    }
    &__category{
        padding: 10px;
        border-bottom: 1px solid #f7f7f7;

        &--dots {
            overflow:hidden;
            white-space:nowrap;
            text-overflow: ellipsis;
        }

        &--beige{
            background-color: #f7f7f7;
        }
        &__title{
            font-size: 16px;
        }
        &__label{
            border-radius: 10px;
            color: #fff;
            font-size: 12px;
            margin-left: 10px;
            float: right;
            padding: 3px 6px;
            &--red{
                background-color: #dd4b39;
            }
            &--green{
                background-color: #00a558;
            }
            &--light-blue{
                background-color: #00c0ef;
            }
            &--blue{
                background-color: #0071b6;
            }
        }
    }
}
@media (max-width: 767px){
    .card{
        width: 100%;
        min-width: 270px;
    }
}
