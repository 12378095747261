@import "../sprite";

.icon-pp {
  background-repeat: no-repeat;
  display: inline-block;
}

.icon-pp-dashboard {
  @include sprite($dashboard);
}
.icon-pp-search {
  @include sprite($search);
}
.icon-pp-message {
  @include sprite($message);
}

.icon-pp-arrow-down {
  @include sprite($arrow-down);
}

.icon-pp-search-top {
  @include sprite($search-top);
  position: absolute;
  z-index: 9;
  top: 11px;
  left: 20px;
}

.icon-pp-location {
  @include sprite($location);
  margin-right: 5px;
  top: 2px;
  position: relative;
}

.icon-pp-advertise {
  width: 27px;
  height: 23px;
  background-position: -5px -5px;
}

.icon-pp-advertise-active {
  width: 27px;
  height: 23px;
  background-position: -5px -38px;
}

.icon-pp-calendar {
  width: 23px;
  height: 26px;

  background-position: -5px -71px;
}

.icon-pp-calendar-active {
  width: 23px;
  height: 26px;
  background-position: -5px -107px;
}




.icon-pp-help {
  width: 25px;
  height: 25px;
  background-position: -5px -205px;
}

.icon-pp-help-active {
  width: 25px;
  height: 25px;
  background-position: -5px -240px;
}

.icon-pp-message-active {
  width: 22px;
  height: 16px;
  background-position: -5px -301px;
}


.icon-pp-search-active {
  width: 21px;
  height: 21px;
  background-position: -5px -358px;
}

.icon-pp-tennants {
  width: 23px;
  height: 24px;
  background-position: -115px -64px;
  background-image: url(../img/sprite.png);
}

.icon-pp-properties-gg {
  width: 27px;
  height: 29px;
  background-image: url(/img/gg_logo_grey.png);
}

.icon-pp-properties-gg-active {
  width: 27px;
  height: 29px;
  background-image: url(/img/gg_small.png);
}

.icon-pp-tennants-active {
  width: 29px;
    height: 23px;
  background-position: -115px -30px;
  background-image: url(../img/sprite.png);
}

.icon-pp-properties {
      width: 29px;
    height: 23px;
    background-position: -66px -71px;
  background-image: url(../img/sprite.png);
}

.icon-pp-properties-active {
  width: 23px;
  height: 24px;
  background-position: -115px 0px;
  background-image: url(../img/sprite.png);
}

.icon-pp-vendors {
  width: 28px;
  height: 20px;
  background-position: -5px -457px;
}

.icon-pp-vendors-active {
  width: 28px;
  height: 20px;
  background-position: -5px -487px;
}

.icon-pp-website {
  width: 29px;
  height: 26px;
  background-position: -5px -517px;
}

.icon-pp-website-site {
  width: 29px;
  height: 26px;
  background-position: -5px -553px;
}


a.pdf:before {
  font-family: FontAwesome;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: normal !important;
  content: "\f1c1";
  color: darkred;
  background: #fff;
  margin-right: 4px;
  font-size: 16px;
}