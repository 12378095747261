.b-form {
  // [class*="col-"] {
  //   padding-left: 0 !important;
  //   padding-right: 0 !important;
  // }
  color: #4e4e4e;
  &.-loading {
    opacity:0.4;
    transition: opacity .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out;
    -webkit-transition: opacity .3s ease-in-out;
    &:after {
      position: absolute;
      content: '';
      top: 40%;
      left: 50%;
      margin: -30px 0 0 -30px;
      border-radius: 100%;
      -webkit-animation-fill-mode: both;
              animation-fill-mode: both;
      border: 4px solid #000;
      height: 60px;
      width: 60px;
      background: transparent !important;
      display: inline-block;
      -webkit-animation: pulse 1s 0s ease-in-out infinite;
              animation: pulse 1s 0s ease-in-out infinite;
    }
  }
  &__form {

  }
  &__fbox {
    background-color: #fff;
    padding: 6px 20px;
  }
  &__fbox-icon {
    display: inline-block;
    vertical-align: middle;
    color: #6b8091;
    margin-right: 15px;
    i {
      font-size: 30px;
    }
  }
  &__fbox-item {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    color: #6b8091;
    label {
      margin: 0;
    }
  }

  &__flds-group {
    &.-btns {
      padding-top: 20px;
    }
  }
  &__flds-group-title {
    padding-bottom: 10px;
    font-size: 13px;
    font-weight: bold;
    &.-up {
      text-transform: uppercase;
    }
  }
  &__flds-group-note {
    padding-bottom: 10px;
    font-size: 13px;
    font-weight: 200;
    padding-left: 15px;
  }

  &__flds-subgroup {
    padding-left: 15px;
  }
  &__flds-subgroup-body {
    padding-left: 15px;
    margin-bottom: 10px;
  }

  &__group {
    margin-bottom: 5px;
  }
  &__label {
    padding-top: 6px;
    font-size: 13px;
    font-weight: 300;
  }
  &__control {

  }
  &__checkbox,
  &__radio {
    display: inline-block;
    margin-right: 10px;
    white-space: nowrap;
    &.-mt {
      margin-top: 5px;
    }
    &.-normal {
      label {
        font-weight: normal;
      }
    }
    &:last-child {
      margin-right: 0px;
    }
    input[type="radio"] {
      margin-right: 5px;
      margin-top: 0px;
      vertical-align: middle;
    }
  }
  &__hint {
    color: #97a8b5;
  }
  &__submit-btn {
    max-width: 240px;
    display: inline-block;
    padding: 5px 16px;
    font-size: 22px;
    border-radius: 0;
    font-weight: 200;
  }

  hr#{&}__separator {
    border-top: 3px solid #41c8f2;
    &.-light {
      border-top: 1px solid #41c8f2;
    }
    &.-gray {
      border-top: 1px solid #dae7f1;
    }
  }
}

.b-alert-settings {
  &__title {
    color: $pp-blue-color;
    font-size: 12px;
    font-weight: bold;
  }
  &__body-box {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
  }
  &.-ghost &__body-box {
    background-color: transparent;
  }
  &__condition {
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
  }
  &__condition-hint {
    color: #97a8b5;
  }
  &__grid-label {
    padding-top: 6px;
  }
  &__grid-item {
    margin-bottom: 5px;
  }
  &__add-btn {
    color: darken($pp-blue-color, 10%);
    margin: 10px 0 0;
    cursor: pointer;
    &:hover {
      color: darken($pp-blue-color, 15%);
    }
  }
  hr {
    border-top: 1px solid #dae7f1;
  }
  .b-form__checkbox {
    padding-top: 3px;
  }
}

.b-tenants-notes {
  margin-bottom: 10px;
  &__item {
    // margin-bottom: 10px;
  }
  &__note-date {
    color: #97a8b5;
    margin-bottom: 5px;
  }
  &__note {

  }
}
