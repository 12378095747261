span.twitter-typeahead .tt-menu,
span.twitter-typeahead .tt-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000 !important;
    display: none;
    float: left;
    min-width: 160px;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    width: 100%;
    line-height: 1;
    overflow: hidden;
}

span.twitter-typeahead pre {
    margin: 0;
}

span.twitter-typeahead .tt-dataset {
    margin: 0;
}

span.twitter-typeahead .tt-suggestion {
    display: block;
    padding: 5px 10px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333333;
    white-space: wrap;
}

span.twitter-typeahead .tt-suggestion.tt-cursor,
span.twitter-typeahead .tt-suggestion:hover,
span.twitter-typeahead .tt-suggestion:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    background-color: #337ab7;
    cursor: pointer;
}

span.twitter-typeahead {
    width: 100%;
}

.input-group span.twitter-typeahead {
    height: 34px;
    display: flex !important;
    width: 235px;
}
/*.input-group span.twitter-typeahead span:nth-last-of-type(2) {
    position: absolute !important;
}*/
.input-group span.twitter-typeahead span:nth-last-of-type(2) input:nth-last-of-type(1) {
    position: absolute !important;
}

.input-group span.twitter-typeahead .tt-menu,
.input-group span.twitter-typeahead .tt-dropdown-menu {
    top: 36px !important;
}

// typeahead
.suggestion-header {
    color: #979aa2;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    background: #f6f7f9;
    padding: 5px 10px;
    border-top: 1px solid #e9eaed;
    border-bottom: 1px solid #e9eaed;
    margin: 0;
}

.suggestion-footer {
    border-radius: 4px;
}

.meta-info {
    font-size: 13px;
    color: #b6b6b7;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.meta-img {
    width: 36px;
    height: 36px;
    float: left;
    margin-right: 8px;
}

.popular-search-icon {
    background: #4f85e8;
    border-radius: 36px;
    border: 0;
    font-size: 16px;
    padding: 10px;
    color: white;
    float: left;
    margin-right: 8px;
}
