.search-result-box {
    width: 100%;
    margin-bottom: 10px;
    padding: 6px;
    font-size:12px;
    border: 1px solid #2c66bd;
    font-family: 'Exo 2', sans-serif;
    font-weight: 300;
    &:last-child {
        margin-bottom: 0;
    }
    &.compact{
        .search-result-box__details{
            display: none;
        }
    }
    &__address{
        &__title{
            color: $header-base;
            font-size: 14px;
            padding-left: 15px;
            display: inline-block;
            float: left;
//            width: calc(100% - 240px);
        }
        &__buttons{
            padding-right: 15px;
            float: right;
            display: inline-block;
            float: right
        }
    }
    &__details{
        &__column{
            &-center{
                @media (min-width: 767px) {
                    border-right: 1px solid $header-base;
                    border-left: 1px solid $header-base;
                }
            }
            &__title{
                color: $header-base;
                font-size: 14px;
                text-transform: uppercase;
            }
            &__small{
                padding-bottom: 10px;
                font-size: 10px;
                line-height: 10px;
                &__block{
                    padding-bottom: 0px;
                }
            }
        }
    }
    &__toggle{

        > i{
            font-size:11px;
        }
        &:not(.compact){
          > .fa-list-alt{
            display: none;
          }
        }
        &.compact{
            > .fa-list-ul{
                display: none;
            }
        }
        &.float-left{
            float: left;
        }
    }
}
.search-result-box {
    @media (min-width: 768px) {
        &.narrow{
            .search-result-box__details__column__small__block{
                text-align: right;
            }
        }
        &.extra-narrow{
            .search-result-box__details{
                &__column{
                    &:last-child,
                    &-center{
                        .col-xs-6{
                            width: 100%;
                        }
                    }
                }
            }
        }
        &.like-mobile{
            .search-result-box{
                &__details{
                    > .row{
                        > .col-sm-4{
                            width: 100%;
                        }
                    }
                    &__column{
                        &-center{
                            border-left: none;
                            border-right: none;
                        }
                    }
                }
                &__address{
                    &__title{
                        width: calc(100% - 140px);
                    }
                    &__buttons{
                        .btn > span{
                            display: none;
                        }
                    }
                }
            }
            .agency-bar {
                &__left, &__right {
                    width: 100%;
                }
                &__right {
                    margin-top: 5px;
                }
            }
            &.w-300{
                .agency-bar {
                    &__right {
                        &__violation {
                            min-height: 73px;
                            width: 30%;
                            &--double {
                                width: 60%;
                            }
                        }
                    }
                }
            }
            &.w-480{
                .agency-bar {
                    &__right {
                        &__violation {
                            width: 20%;
                            &--double {
                                width: 40%;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width:550px) {
    .search-result-box__address__buttons{
        .btn > span{
            display: none;
        }
    }
}
@media (max-width: 400px) {
    .search-result-box__details__column__small__block{
        text-align: right;
    }
}
@media (max-width: 300px) {
    .search-result-box__details{
        &__column{
            &:last-child,
            &-center{
                .col-xs-6{
                    width: 100%;
                }
            }
        }
    }
}
