.m-inline-b {
  display: inline-block;
}

.m-t-10 {
  margin-top: 10px;
}

.m-loading-state {
  opacity:0.4;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  &:after {
    position: absolute;
    content: '';
    top: 40%;
    left: 50%;
    margin: -30px 0 0 -30px;
    border-radius: 100%;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    border: 4px solid #000;
    height: 60px;
    width: 60px;
    background: transparent !important;
    display: inline-block;
    -webkit-animation: pulse 1s 0s ease-in-out infinite;
            animation: pulse 1s 0s ease-in-out infinite;
  }
}
