.l-dashboard {
  &__content {
    background-color: $pp-bg-color;
    padding: 20px;
    &.-wo-padding {
      padding: 0;
    }
  }
  &__content.-wo-padding &__h-wrapper {
    padding: 20px;
  }
  &__classic-wrapper {
    padding: 20px;
  }
  &__h-wrapper {
    padding-bottom: 20px;
  }
  &__h {
    display: inline-block;
    margin-right: 20px;
    font-size: 24px;
    font-weight: 200;
    color: #6b8091;
    margin-bottom: 15px;
  }
  &__sub-h {
    color: #97a8b5;
    font-size: 12px;
    margin-bottom: 15px;
  }
  &__h-group {
    position: relative;
  }
  &__h-group &__h {
    margin-bottom: 5px;
  }
  &__h-group &__sub-h {
    margin-bottom: 5px;
  }
  &__btns {
    display: inline-block;
    margin-top: 2px;
    vertical-align: top;
    .btn {
      text-transform: uppercase;
      border-radius: 0;
      font-size: 16px;
      padding: 3px 12px;
      margin-right: 5px;
    }
  }
}

.l-login {
  display: flex;
  height: 100%;
  width: 100%;
  &__centering-helper {
    margin: auto;
    width: 100%;
    padding: 20px 0;
  }
}
