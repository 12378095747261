@import url(https://fonts.googleapis.com/css?family=Exo+2:400,100,200,300,500,600,700,800,500italic,300italic);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700);

$font-family-base: 'Exo 2', sans-serif !default;
$font-size-base: 11px !default;
$font-size-big: 17px;
$font-size-large: 28px;
$font-size-small: 8px;

$font-family-open-sans:   'Open Sans', sans-serif;;

$color-light-blue: #def5ff;
$color-dark-blue: #6b8091;

$color-blue-1: #11aff0;
$color-border-form: #abd7e9;

$color-gray: #7a7a7a;
$color-gray-1: #484848;
$color-gray-2: #6f6f6f;

$color-green: #eff9c9;

$light-sky-blue:#cef0ff;
$sky-blue:#37b1f3;
$black:#333;
$white:#fff;
$light-sky-blue-bg:#dff4ff;
$light-yellow:#fef79b;
$dark-blue:#346bc0;
$light-grey-bg:#ebebeb;
$blue-btn:#0e9eda;
$sky-blue-text:#11aff0;
$dark-grey-text:#484848;
$border-blue:#2c66bd;
$dark-brown: #9b1f0f;
$red-text:#ff442a;
$brown-text:#690202;
$dark-blue:#00146d;
$tab-border:#f4f4f4;
$list-dark-blue:#2a62b5;
$vendor-color:#a864a8;
$user-color:#30a5e8;
$search-form-bg: #3abfe8;


$color-blue-default: #41c8f2;


$color-light-gray-1: #eef3f6;
$color-light-gray-2: #8d989f;
$color-light-gray-3: #708495;
$color-light-gray-4: #7b8d9d;
$color-light-gray-5: #586c7c;
$color-light-gray-6: #97a8b5;
$color-light-gray-7: #525f6a;


$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs !default;

$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;

$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;

$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;

$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
