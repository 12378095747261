.agencies-menu {
  width:251px;
  background: #41c8f2;
  overflow: hidden;
  .sub-sub-menu{
    >li >a{
      margin-left:50px;
    }
  }
  ul {
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 13px;
    line-height: 0.5em;
    list-style: none;
    position: relative;
  }
  .agency_icon{
    top: 10px;
  }
  &__agency {
    &:hover{
      cursor: pointer;
    }
    b {
      float: right;
      margin-right: 10px;
    }
    &.open {
      > a {
        border-left: 2px solid #11aff0;
      }
    }
    > a {
      border-left: 2px solid #41c8f2;
      display: block;
      width: 250px;
      line-height: 35px;
      min-height: 35px;
      color: #fff;
      font-weight: normal;
      padding: 0px;
      padding-left: 15px;
      margin: 0;
      text-align: left;
      font-size: 12px;
      box-sizing: border-box;
      > i {
        width: 24px;
        height: 33px;
        padding: 0px;
        margin-right: 12px;
        float: left;
        display: inline-block;
        text-align: center;
        position: relative;
//        top: 10px;
        font-size: 16px !important;
      }
    }
    &__sub-menu {
      li {
        > a {
          color: #fff;
          padding: 8px 15px 8px 40px;
          line-height: 20px;
          width: 100%;
          display: block;
          &:hover, &.active {
            background: #11aff0;
            color: #fff;
          }
        }
      }
    }
  }


}

@media (max-device-width: 767px ) {
  .agencies-menu__agency{
    width:inherit;
  }
  .fa-angle-right {
    margin: 0 4px;
  }
  .fa-angle-right:before {
    content: "\f105";
  }
  .agencies-menu {
    width:inherit;
    background: #41c8f2;
    overflow: hidden;

    &__agency {
      &:hover {
        cursor: pointer;
      }
      b {
        float: right;
        margin-right: 10px;
      }
      &.open {
        > a {
          border-left: 2px solid #11aff0;
        }
      }
      > a {
        border-left: 2px solid #41c8f2;
        display: block;
        line-height: 35px;
        width: inherit;
        min-height: 35px;
        color: #fff;
        font-weight: normal;
        padding: 0px;
        padding-left: 15px;
        margin: 0;
        text-align: left;
        font-size: 12px;
        box-sizing: border-box;
        > i {
          width: 24px;
          height: 33px;
          padding: 0px;
          margin-right: 12px;
          float: left;
          display: inline-block;
          text-align: center;
          position: relative;
          top: 10px;
          font-size: 16px !important;
        }
      }
    }
    .sub-sub-menu{
      >li >a{
        margin-left:50px;
      }
    }

  }
}
