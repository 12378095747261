.tooltip.top .tooltip-inner {
    font-size: 12px;
    min-width: 200px;
}
.tooltip{
    position: fixed;
    z-index: 99;
}

*{
  font-family: Poppins, sans-serif;
}
span.overbilt{
    font-size: 11px;
}

.join-us-modal .modal-content {
    background: #eef3f6;

    .modal-header {
        padding: 15px 30px;
        border: 0;

        h4 {
            font-size: 19px;
            font-weight: bold;
            color: #39bfe8;

            b {
                font-weight: bold;
                color: #0090bc;
            }
        }
    }

    .modal-body {
        padding: 0 30px;
        font-size: 14px;
        color: #525f6a;
        line-height: 1.4;
    }

    .modal-footer {
        border: 0;
        padding: 15px 30px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #525f6a;

        a {
            &:active, &:visited, &:link {
                display: block;
                text-decoration: none;
                outline: none;
                width: 270px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: #fff;
                margin: 5px auto 0;
                background: #41c8f2;
                border-radius: 40px;
                font-size: 20px;
                font-weight: bold;
                text-transform: uppercase;
            }

            &:hover {
                background-color: #119ec9;
                cursor: pointer;
            }
        }
    }
}
.search_save_modal{
    margin: 84px auto 0px;
}
.property {
    margin: 0;
    height: 100%;
    .distance{
        text-align:center;
    }
    .forms_container {
        width: calc(100% - 251px);
        float:left;

        .property_form_block {
            border: 1px solid #ccc;
            background: #fff;
            padding: 20px;

            b {
                font-size: 16px;
                font-weight: bold;
            }

            .property_form_block_inner {
                padding-top: 15px;
                font-size: 13px;

                a {
                    font-weight: bold;
                }

                p {
                    font-style: italic;
                }
            }
        }

        .property_form_block + .property_form_block {
            border-top: 0;
        }
    }

    .join-us-header {
        background: #fff;
        padding: 20px 40px;

        .join-us-title {
            font-size: 20px;
            color: #119ec9;
            font-weight: bold;

            b {
                color: #39bfe8;
            }
        }

        .join-us-description {
            font-size: 14px;
            color: #525f6a;
            padding-top: 5px;

            b {
                font-weight: bold;
            }
        }

        .join-us-button {
            &:active, &:visited, &:link {
                display: block;
                width: 150px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                color: #fff;
                background: #41c8f2;
                border-radius: 30px;
                font-size: 18px;
                font-weight: bold;
                text-decoration: none;
                outline: none;
                text-transform: uppercase;
                margin-top: 15px;
            }

            &:hover {
                cursor: pointer;
                background-color: #119ec9;
            }

            @media (min-width: 1478px) {
                &:active, &:visited, &:link, &:hover {
                    margin-top: 0;
                    display: inline-block;
                    margin-left: 50px;
                }
            }
        }
    }

    &.property-details-fullwidth {
        width: 100%;
    }

    &__header {
        background-color: $color-light-gray-1;
        position: relative;
        padding: 25px 40px;
        &__left{
            float:left;
            display: inline-block;
            padding-left: 15px;
            width: calc(100% - 300px);
        }
        &__right{
            float: right;
            display: inline-block;
            padding-right: 15px;
        }
    }
    &__title {
        color: $color-dark-blue;
        font-size: 40px;
        line-height: 1.2;
        @include breakpoint(sm) {
          font-size: 22px;
        }
        @include breakpoint(xs) {
          font-size: 18px;
        }
    }
    &__subtitle {
        color: $color-light-gray-6;
        font-size: 12px;
    }
    &-map{
        // min-height: 400px;
        min-height: 100%;
    }
}
@media (min-width:$screen-sm-min){
    .property{
        width: 60%;
        display: inline-block;
        vertical-align: top;
        overflow-x: hidden;

        &-map{
            width:40%;
            display:inline-block;
            float:right;
            // min-height: 600px;
        }
        &-wrapper{
            margin-right: 0px !important;
        }
    }
}
.w-less-800{
    .property{
        &__header {
            &__left{
                width: calc(100% - 160px);
            }
            &__right{
                &__button{
                    span{
                        display:none;
                    }
                }
            }
        }
    }
}
@media(max-width:600px){
    .property{
        &__header {
            &__left{
                width: calc(100% - 160px);
            }
            &__right{
                &__button{
                    span{
                        display:none;
                    }
                }
            }
        }
    }
}
@media(max-width:$screen-xs-max){
    .property{
        .forms_container {
            width: 100%;
            float: none;
        }
    }
    .property-map{
        min-height: 80%;
    }
}
@media(max-width:479px){
    .property{
        &__header {
            &__left{
                width: 100%;
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 10px;
            }
            &__right{
                width: 100%;
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 10px;
            }
        }
    }
}

.property-map {
    &__street_view_icon{
      width: 37px;
      position: absolute;
      right: 0;
      bottom: 22px;
      cursor: pointer;
    }
    &__title {
        font-size: 12px;
        color: $color-blue-1;
        margin: 5px 0;
    }
    &__label {
        color: $color-gray;
        font-size: 12px;
    }
    &__value {
        color: $color-gray;
        font-size: 12px;
    }
    &__streetview {
        height: 343px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-top: 16px;
    }
    &__google {
        padding: 0;
    }
}

.property-block {
    background: $color-light-gray-1;
    padding-top: 25px;
    .sub_elem{
      padding: 0 0 0 14px;
    }
    &__header {
        color: $color-dark-blue;
        font-size: 16px;
        margin-top: 15px;
    }
    &__content {
        margin: 0 15px;
    }
    &__notification {
        margin-top: 15px;
        float: right;
        padding-right: 7px;
    }
    img {
        max-width: 100%;
    }
    &__bg-blue{
        background: $color-light-blue;
        &--wide{
            background: $color-light-blue;
        }
    }
}

.property-owner {
    &__header {
        color: $color-dark-blue;
        margin: 20px 0;
        font-size: 17px;
    }

    &__name {
        color: $color-gray;
        font-size: 22px;
    }
    &__description {
        color: $color-gray;
        font-size: 16px;
        margin-bottom: 30px;
    }
    &__info {
        color: $color-gray;
        font-size: 16px;
    }
}

.link-more {
    color: $color-dark-blue;
    font-size: 16px;
}

.no-min-height{
  min-height: 0px !important;
}
.math_icon {
  /* float: left; */
  margin-left: 22%;
  font-size: 10px;
}
.no-right-pading{
    // Most probably we don't need it for now
    // @media(min-width:$screen-md-min){
    //     padding-right: 0px;
    // }
}
.min-height-25{
  min-height: 25px !important;
}
.sub_title{
  padding-left: 5px;
}

//General Style
.block-element {
    margin-bottom: 18px;
    overflow:hidden;
    &__title {
        color: #000000;
        font-size: 12px;
    }
    &__container{
      background-color: #FAFCFD;
      display: inline-block;
      width: 100%;
      padding: 3px 0;
      div{
        padding:0px 1px 0 3px;
      }
    }

    &__label {
        font-size: 12px;
        color: #ADB7BE;
    }

    &__value {
        font-size: 12px;
        color: black;
    }
}
.inline-block {
    display: inline-block;
}
.bg-white {
    background: #fff;
}

.additional-filter {
    padding: 0;
    margin: 0;
    &__element {
        list-style: none;
        margin-bottom: 10px;
    }
}

.table-element {
    background: #FAFCFD;
    padding: 5px 0px;
    overflow-x: auto;
}
.property-block__header.tbale_header{
  margin: 3px 0px 9px 3px;
  font-size: 20px;
  color: black;
}
.table-statistics {
    width: 100%;
    max-width: 100%;
    &--debt {

    }
    thead {
        th {
            font-weight: 200;
            font-size: 12px;
            padding: 5px;
            vertical-align: top;
        }
    }
    tr {
        color: $color-light-gray-5;
        font-size: 12px;

        td {
            vertical-align: top;
            padding: 5px 10px;

        }
        .date{
          padding:5px;
        }

    }
    &__info {
        font-size: 10px;
    }
    &__sum {
        text-align: right;
        &--th{
            text-align: center;
        }
    }
    &__row-with-border{
        &--top{
            border-top:1px solid #aeaeae;
        }
        &--bottom{
            border-bottom:1px solid #aeaeae;
        }
    }
}

.text{
    &--red{
        color: red;
    }
    &--green{
        color: green;
    }
}

@media (min-width: 1200px) and (max-width:1537px){
    .control-sidebar-open {
        #tab_debt {
            .block-element.gray-box{
                min-height: 122px;
            }
        }
    }
}

.subway-item {
    margin-bottom: 5px;
}

span.sub_icon{
  width: 11px;
  height: 11px;
  background: #000;
  -webkit-border-radius: .9em;
  border-radius: .9em;
  float: left;
  margin-right: 2px;
  font-size: 7px;
  text-align: center;
  color: #FFF;
  padding-top: 1px;
  margin-bottom: 2px;
}
.leaflet-item-div{
  width:60px !important;
  border:0px;
}
.leaflet-sub_icon{
  width: 11px;
  height: 11px;
  background: #000;
  -webkit-border-radius: .9em;
  border-radius: .9em;
  float: left;
  margin-right: 1px;
  font-size: 7px;
  text-align: center;
  color: #FFF;
  padding-top: 1px;
  margin-bottom: 0px;
}

span.leaflet-sub_icon.line_A,
span.leaflet-sub_icon.line_C,
span.leaflet-sub_icon.line_E {
  background:#0039A6;
}
span.leaflet-sub_icon.line_1,
span.leaflet-sub_icon.line_2,
span.leaflet-sub_icon.line_3{
  background:#ee352e
}

span.leaflet-sub_icon.line_4,
span.leaflet-sub_icon.line_5,
span.leaflet-sub_icon.line_6{
  background:#00933c;
}

span.leaflet-sub_icon.line_R,
span.leaflet-sub_icon.line_W,
span.leaflet-sub_icon.line_Q,
span.leaflet-sub_icon.line_N {
  background:#fccc0A
}

span.leaflet-sub_icon.line_G {
  background:#6cbe45
}

span.leaflet-sub_icon.line_7 {
  background:#b933ad;
}

span.leaflet-sub_icon.line_L
{
  background:#a7a9ac
}
span.leaflet-sub_icon.line_S{
  background:#808183
}

span.leaflet-sub_icon.line_J,
span.leaflet-sub_icon.line_Z {
  background:#996633
}

span.leaflet-sub_icon.line_F,
span.leaflet-sub_icon.line_M,
span.leaflet-sub_icon.line_V,
span.leaflet-sub_icon.line_B,
span.leaflet-sub_icon.line_D {
  background:#ff6319
}
span.leaflet-sub_icon.line_Ferry {
  background:#87cefa;
  width:3.2em
}

span.leaflet-sub_icon.line_PATH {
  background:#010099;width:3.2em
}

span.leaflet-sub_icon.line_LIRR {
  background:#010099;width:3.2em
}

span.leaflet-sub_icon.line_Ferry,
span.leaflet-sub_icon.line_LIRR,
span.leaflet-sub_icon.line_PATH {
  border:0;
  border-radius:2px;
  -webkit-border-radius:2px;
  -moz-border-radius:2px
}

span.leaflet-sub_icon.line_R,
span.leaflet-sub_icon.line_W,
span.leaflet-sub_icon.line_Q,
span.leaflet-sub_icon.line_N {
  color:#000;
  border:1px solid #fccc0A;
  padding:0px;
}

span.leaflet-sub_icon.line_A,
span.leaflet-sub_icon.line_C,
span.leaflet-sub_icon.line_E {
    background:#0039A6;
}
span.sub_icon.line_1,
span.sub_icon.line_2,
span.sub_icon.line_3{
    background:#ee352e;
}

span.sub_icon.line_4,
span.sub_icon.line_5,
span.sub_icon.line_6{
    background:#00933c;
}

span.sub_icon.line_R,
span.sub_icon.line_W,
span.sub_icon.line_Q,
span.sub_icon.line_N {
    background:#fccc0A;
}

span.sub_icon.line_G {
    background:#6cbe45
}

span.sub_icon.line_7 {
    background:#b933ad
}

span.sub_icon.line_L{
  background:#a7a9ac
}
span.sub_icon.line_S{
    background:#808183
}

span.sub_icon.line_J,
span.sub_icon.line_Z {
    background:#996633
}

span.sub_icon.line_F,
span.sub_icon.line_M,
span.sub_icon.line_V,
span.sub_icon.line_B,
span.sub_icon.line_D {
    background:#ff6319
}
span.sub_icon.line_Ferry {
    background:#87cefa;
    width:3.2em
}

span.sub_icon.line_PATH {
    background:#010099;width:3.2em
}

span.sub_icon.line_LIRR {
    background:#010099;width:3.2em
}

span.sub_icon.line_Ferry,
span.sub_icon.line_LIRR,
span.sub_icon.line_PATH {
    border:0;
    border-radius:2px;
    -webkit-border-radius:2px;
    -moz-border-radius:2px
}

span.sub_icon.line_R,
span.sub_icon.line_W,
span.sub_icon.line_Q,
span.sub_icon.line_N {
    color:#000;
    border:1px solid #fccc0A;
    padding:0px;
}

.property-map-value-clearfix {
    display: inline-block;
    vertical-align: middle;
}

.subway-item {
    border-bottom: 1px solid #c9d0d6;
    margin-bottom: 5px;
    padding-bottom: 5px;
}
.property-map-value-subway {
    display: block;
    width: 100%;
}
.pano_content{
  margin-top:16px;
}

@media(max-width:415px){
  .block-element{
    &__label{
      font-size:10px;
    }
    &__value{
      font-size:10px;
    }
  }
  .property-block{
    .col-md-12{
      padding:0px !important;
    }
  }

  .no-xs-padding{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .property__header{
    &__left{

    }
    &__right{
      padding-left: 15px;
      margin-top: 10px;
    }
  }
  .distance{
    font-size:11px;
  }
  .block-element{
    &__value{
      font-size:11px;
    }
  }

}
