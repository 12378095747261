.comps-section{
    padding:0;
    /*profile-header*/
    .profile__header{
        padding: 25px;
        height: 315px;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        &.profile__header--user{
            background-color: $user-color;
        }
        /*alert-bar*/
        .alert{
            &.alert-danger{
                font-size: 14px;
                text-align: center;
                cursor: pointer;
                padding: 9px;
                @media screen and (max-width:767px){
                    padding:6px;
                }
                &.alert-dismissible {
                    .close{
                        top: 0;
                        right: 0;
                    }
                }
            }
            @media screen and (max-width:520px){
                position: relative;
                z-index: 6;
            }
        }
        /*alert-bar-ends*/
        /*profile-image*/
        .profile__image__datails{
            position:absolute;
            bottom:15px;
            z-index:4;
            .profile-pic{
                #avatar {
                    -webkit-border-radius: 4px !important;
                    -moz-border-radius: 4px !important;
                    border-radius: 4px !important;
                    margin-right: 10px;
                    padding: 0;
                    max-width: 150px;
                    height: 145px;
                    border-radius: 8px;
                    border: 3px solid white;
                    float: left;
                    @media screen and (max-width:991px){
                        float:none;
                        margin-right:0px;
                    }
                }
            }
            img{
                &.profile-pic{
                    margin-right: 10px;
                    padding: 0;
                    max-width: 150px;
                    height: 145px;
                    border-radius: 8px;
                    border: 3px solid white;
                    float: left;
                    @media screen and (max-width:991px){
                        float:none;
                        margin-right:0px;
                    }
                    #avatar {
                        margin-right: 10px;
                        padding: 0;
                        max-width: 150px;
                        height: 145px;
                        border-radius: 8px;
                        border: 3px solid white;
                        float: left;
                        @media screen and (max-width:991px){
                            float:none;
                            margin-right:0px;
                        }
                    }
                }
            }
            /*profile-details*/
            .profile__details{
                text-shadow:2px 2px 2px $black;
                float: left;
                margin-top: 62px;
                p{
                    color:$white;
                    margin-bottom: 3px;
                    font-weight: 600;
                    font-size: 13px;
                    &.profile__details--name{
                        font-weight: 500;
                        font-size: 22px;
                    }
                }
                @media screen and (max-width:991px){
                    margin-top: 0px;
                }
            }
            /*profile-details*/
            @media screen and (max-width:991px){
                left: 50%;
                transform: translate(-50%, 0);
            }
            @media screen and (max-width:520px){
                bottom: 52px;
            }
        }
        /*profile-image-details-ends*/
        /*profile-connect*/
        .profile__connect{
            position:absolute;
            bottom:20px;
            right :22px;
            text-align: left;
            .connect-buttons{
                float:right;
                .profile__connect--btn-blue{
                    background-color: $blue-btn;
                    color:$white;
                    text-transform: uppercase;
                    padding: 5px 20px;
                    font-weight: 500;
                    border-radius: 0;
                    min-width: 105px;
                    box-shadow: none;
                    @media screen and (max-width:991px){
                        display:none;
                        &:first-child{
                            display: block;
                            float: left;
                            margin-right: 30px;
                        }
                    }
                }
            }
        }
        /*profile-connect-nav-mobile*/
        .bootstrap_btn_mobile {
            display: none;
            z-index: 7;
        }
        @media screen and (max-width:991px){
            .bootstrap_btn_mobile {
                display: block;
                max-width: 150px;
                text-align: center;
                position: absolute;
                right: 0px;
                .navbar-toggle{
                    margin: 0;
                    background: $blue-btn;
                    border-radius: 0;
                    padding: 5px;
                    display: block;
                    .fa-bars{
                        font-size: 19px;
                        color: $white;
                    }
                    #close-sign{
                        display: none;
                        position: absolute;
                        top: -1px;
                        right: -1px;
                        left: -1px;
                        bottom: -1px;
                        background-color: $blue-btn;
                        font-size: 19px;
                        line-height: 30px;
                        color:$white;
                    }
                    .show{
                        display: block;
                    }
                }
                a{
                    display: none;
                    text-transform: uppercase;
                    background-color: $blue-btn;
                    width: 100%;
                    padding: 10px 0;
                    color:$white;
                    font-weight: 500;
                    float: left;
                    padding:7px 15px 8px 15px;
                    margin-bottom:1px;
                    margin-top: -45%;
                    &:hover{
                        text-decoration: none;
                    }
                    &:last-child{
                        margin-top: -68%;
                    }
                }
            }
        }
        /*profile-connect-nav-mobile ends*/
        /*profile-connect-ends*/
    }
    /*profile-header-ends*/


    /*.profile-timeline*/
    .profile__timeline{
        color:$black;
        position:relative;
        width:100%;
        float:left;

        /*contact-info*/
        .profile__timeline--contact-info{
            background-color: $light-sky-blue-bg;
            font-size: 17px;
            font-weight: 200;
            padding:0px;
            h4{
                margin-bottom: 0;
            }
            ul{
                background-color: $light-sky-blue-bg;
                li{
                    font-size:14px;
                    a{
                        color:$black;

                        &:hover{
                            color:$light-sky-blue;
                        }
                        .fa-angle-left{
                            float:right;
                        }
                    }
                }
                &:nth-child(1){
                    li{
                        a{
                            border-left:none;
                        }
                        &:hover{
                            a{
                                background:$light-sky-blue-bg;
                                border-left:none;
                                color:$black;
                            }
                        }
                        &.active{
                            a{
                                background:none;
                                border-left:none;
                                color:$black;
                            }
                        }
                    }
                }
                .timeline__contact--address{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    .fa-map-marker{
                        font-size: 24px;
                        margin-right: 5px;
                        float: left;
                    }
                }
                .timeline__contact--call{
                    .fa-phone{
                        font-size: 24px;
                        margin-right: 5px;
                        float: left;
                    }
                }
                .timeline__contact--mail{
                    .fa-envelope-o{
                        font-size: 24px;
                        margin-right: 5px;
                        float: left;
                    }
                }
                .timeline__contact--audio{
                    .fa-volume-up{
                        font-size: 24px;
                        margin-right: 5px;
                        float: left;
                    }
                }
            }
            /*video-box*/
            .timeline__video--box{
                margin-top: 20px;
                position: relative;
                padding-bottom: 56.25%;
                padding-top: 25px;
                height: 0;
                margin-left: 10px;
                margin-right: 10px;
                iframe{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }
            /*video-box--ends*/
            .timeline__endorsed{
                padding: 0 10px;
                h2{
                    text-transform: uppercase;
                }
                .timeline__endorsed--block{
                    margin-bottom:30px;
                    p{
                        font-size: 14px;
                        margin-bottom: 0px;
                    }
                }
                .profile__connect--btn-blue{
                    background-color: $sky-blue-text;
                    border-color: $blue-btn;
                    color:$white;
                    width: 100%;
                    text-transform: capitalize;
                    padding: 8px 2px;
                    margin-bottom: 10px;
                    font-size: 16px;
                    font-weight: 500;
                    border: none;
                    border-radius: 0;
                }
            }
        }
        /*contact-info-ends*/
        /*timeline*/
        .profile__timeline--block{
            /*tabs-custom*/
            .nav-tabs-custom {
                font-size: 14px;
                box-shadow:none;
                .nav-tabs{
                    border: none;
                    li {
                        margin-right: 0px;
                        background-color: $light-sky-blue-bg;
                        border-top:none;
                        margin-bottom: 0px;
                        &.active{
                            background-color: $white;
                            border-top: none;
                        }
                    }
                }
                .tab-content{
                    padding: 0;
                    padding-top: 10px;
                }
            }
            /*tabs-custom
            /*search-box-result*/
            .search-result-box {
                width: 100%;
                padding: 6px;
                font-size: 12px;
                border: 1px solid $border-blue;
                margin-top: 0px;
                font-weight: 300;
                .search-result-box__address__title {
                    color: $sky-blue-text;
                    font-size: 14px;
                    padding-left: 15px;
                    display: inline-block;
                    float: left;
                    width: calc(100% - 200px);
                }
                .search-result-box__address__buttons {
                    padding-right: 15px;
                    float: right;
                    display: inline-block;
                    float: right;
                    .btn.btn-flat {
                        border-radius: 0;
                        box-shadow: none;
                        border-width: 1px;
                    }
                    .btn-primary {
                        background-color: $sky-blue-text;
                        border-color: $blue-btn;
                        span{
                            display: none;
                        }
                    }
                }
                .search-result-box__details__column{
                    width:100%;
                    .search-result-box__details__column__title {
                        color: $sky-blue-text;
                        font-size: 14px;
                        text-transform: uppercase;
                    }
                }
                .search-result-box__details__column-center{
                    width:100%;
                    .search-result-box__details__column__title {
                        color: $sky-blue-text;
                        font-size: 14px;
                        text-transform: uppercase;
                    }
                }
                .agency-bar {
                    width: 100%;
                    margin-top: 0px;
                    padding-top: 10px;
                    clear: both;
                    color: $dark-grey-text;
                }
                .agency-bar__left {
                    width: 100%;
                    margin-right: 3px;
                    padding: 3px;
                    float: left;
                    margin-bottom: 5px;
                    background-color: $light-sky-blue-bg;
                    .agency-bar__left__header {
                        font-size: 14px;
                    }
                    .agency-bar__left__title {
                        width: 50px;
                        float: left;
                        font-size: 14px;
                        line-height: 25px;
                    }
                }
                .agency-bar__right {
                    width: 100%;
                    padding-bottom: 5px;
                    float: left;
                    margin-right: 3px;
                    background-color: $light-sky-blue-bg;
                    .agency-bar__right__violation {
                        width: 20%;
                        float: left;
                        text-align: center;
                        .agency-bar__right__violation__title {
                            font-size: 14px;
                            line-height: 25px;
                        }
                        .agency-bar__right__violation__title-small {
                            font-size: 8px;
                            line-height: 9px;
                            margin-bottom: 5px;
                        }
                        .agency-bar__right__violation__count {
                            line-height: 16px;
                            font-weight: 600;
                            font-size: 18px;
                            color: $dark-grey-text;
                        }
                        .agency-bar__right__violation__balance {
                            height: 11px;
                            color: $dark-grey-text;
                            font-size: 8px;
                            line-height: 12px;
                            margin-top: 5px;
                        }
                    }
                    .agency-bar__right__violation--double{
                        width: 40%;
                        .agency-bar__right__violation__title-small {
                            width: 50%;
                            float: left;
                        }
                        .agency-bar__right__violation__title-small {
                            width: 50%;
                            float: left;
                        }
                        .agency-bar__right__violation__count {
                            width: 50%;
                            float: left;
                        }
                        .agency-bar__right__violation__count {
                            width: 50%;
                            float: left;
                        }
                    }
                }
            }
            /*search-result-box-ends*/
            @media screen and (max-width: 767px){
                padding: 0px;
            }
        }
        /*timeline-ends*/
    }
    /*.profile-timeline--ends*/
    @media screen and (max-width: 767px){
        float: left;
        width: 100%;
        padding-left: 7px;
    }
    a{
        cursor: pointer;
    }
}
.right-sidebar{
    @media screen and (max-width: 767px){
        float:left;
        padding-left: 7px;
        padding-right: 1px;
    }
}
